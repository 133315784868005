/** @jsx jsx */
import { Link } from 'gatsby'
import { jsx } from 'theme-ui'
import PropTypes from 'prop-types'
import { useAnalytics } from '@chordcommerce/gatsby-theme-performance'

const ProductLink = ({ slug, children, sx, ...props }) => {
  // trackProductClicked support additional properties such
  // as `placement` and `position`
  const { trackProductClicked } = useAnalytics()

  const url = `/products/${slug}/`

  return (
    <Link
      to={url}
      onClick={() => trackProductClicked({ productHandle: slug })}
      sx={{
        ...sx
      }}
      {...props}
    >
      {children}
    </Link>
  )
}

ProductLink.propTypes = {
  slug: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default ProductLink
